import { Routes, Route } from "react-router-dom";
import {
  Login,
  Home,
  Dashboard,
  Products,
  IasHub,
  ExamDetails,
  ExamGuide,
  SyllabusDashboard,
  SoawDashboard,
  WmpsdDashboard,
  Soaw,
  PreProcess,
  Syllabus,
} from "./Pages/_index";
import { Navigation } from "./Components/_index";
// import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <div className="">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="/" element={<Navigation />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="iashub" element={<IasHub />} />
          <Route path="exam-guide" element={<ExamGuide />} />
          <Route path="syllabus-dashboard" element={<SyllabusDashboard />} />
          <Route path="soaw-dashboard" element={<SoawDashboard />} />
          <Route path="wmpsd-dashboard" element={<WmpsdDashboard />} />
          <Route path="syllabus" element={<Syllabus />} />
          <Route path="preprocess" element={<PreProcess />} />
          <Route path="soaw" element={<Soaw />} />
          <Route path="exam-details" element={<ExamDetails />} />
          <Route path="products" element={<Products />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
