import PreHeader from "./../Assets/preprocess-header.png";
import PreBody from "./../Assets/preprocess.png";

function PreProcess() {
  return (
    <div>
      <div className="px-52 bg-[#F4F6FE]">
        <img src={PreHeader} alt="" className="w-[70rem]" />

        <img src={PreBody} alt="" className="w-[68rem] pl-16" />
      </div>
    </div>
  );
}

export default PreProcess;
