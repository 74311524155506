import ProfHero from "./../Assets/dashboard-hero.png";
import ProLogo from "./../Assets/profile.png";

import React, { useState, useEffect } from "react";
import "typeface-montserrat/index.css";

interface Data {
  _id: string;
  email: string;
  image: string;
  name: string;
  position: string;
  employeeId: string;
  phoneNumber: string;
  academicQualification: string;
  experience: string;
  __v: number;
}

const inital = {
  _id: "",
  email: "",
  image: "",
  name: "",
  position: "",
  employeeId: "",
  phoneNumber: "",
  academicQualification: "",
  experience: "",
  __v: 0,
};

function Dashboard() {
  const [email, setEmail] = useState("");
  const [data, setData] = useState<Data>(inital);

  useEffect(() => {
    const emailFromLocalStorage = localStorage.getItem("email");

    if (emailFromLocalStorage) {
      setEmail(emailFromLocalStorage);
    }
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchData = async () => {
    try {
      const response = await fetch(
        `https://josh-talks-counselling-backend-production.up.railway.app/api/users/${email}`
      );
      const data = await response.json();
      setData(data[0]);
      localStorage.setItem("name", data[0].name);
      localStorage.setItem("image", data[0].image);
      console.log(data[0]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (email) {
      fetchData();
    }
  }, [email]);

  return (
    <div className="pt-10 px-20 font-montserrat w-[100vw]">
      <div className="flex flex-row justify-between items-center">
        <div className="shadow-xl w-[50vw]">
          <div className="flex flex-col md:flex-row lg:flex-row items-center md:items-end lg:items-end bg-[#F7F5F5] pl-3 pr-20 py-5">
            <img src={ProLogo} alt="" />
            <div className="pl-5 my-10">
              <p className="text-4xl font-semibold text-[#434343] font-montserrat">
                {data.name}
              </p>
              <p className="text-3xl font-semibold text-[#434343] font-montserrat">
                {data.position}
              </p>
            </div>
          </div>
          <div className="py-10 bg-[#FBFBFB] pl-3 pr-20">
            <p className="text-xl py-5">
              <div className="flex flex-row">
                <p>
                  Employee
                  ID&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </p>
                <p className="font-medium">{data.employeeId}</p>
              </div>
            </p>
            <p className="text-xl py-5">
              <div className="flex flex-row">
                <p>
                  Phone Number
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </p>
                <p className="font-medium">{data.phoneNumber}</p>
              </div>
            </p>
            <p className="text-xl py-5">
              <div className="flex flex-row">
                <p>
                  Email ID
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </p>
                <p className="font-medium"> {data.email}</p>
              </div>
            </p>
            <p className="text-xl py-5">
              <div className="flex flex-row">
                <div>
                  <p>Academic</p>
                  <p>
                    Qualification
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </p>
                </div>
                <p className="font-medium">{data.academicQualification}</p>
              </div>
            </p>
            <p className="text-xl">
              <div className="flex flex-row">
                <p>
                  Experience&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </p>
                <p className="font-medium">{data.experience}</p>
              </div>
            </p>
          </div>
        </div>
        <img src={ProfHero} alt="" className="w-[28rem] h-[82vh] pt-1" />
      </div>
    </div>
  );
}

export default Dashboard;
