import Product1 from "./../Assets/products1.png";
import Product2 from "./../Assets/products2.png";
import Product3 from "./../Assets/products3.png";
import Product4 from "./../Assets/products4.png";
import Product5 from "./../Assets/products5.png";

function Products() {
  return (
    <div className="py-10 px-20">
      <div className="flex flex-row justify-between">
        <a href="https://drive.google.com/file/d/1CkVc4-2EwS6O520TIgE70x60n9Dti1v1/view?usp=sharing">
          <img src={Product1} alt="" />
        </a>
        <a href="https://drive.google.com/file/d/1VzFric8hG8WPEgmoFryFHQGNbp2UEYLo/view?usp=sharing">
          <img src={Product2} alt="" />
        </a>
      </div>
      <div className="flex flex-row justify-between py-10">
        <a href="https://drive.google.com/file/d/1qJglggyOPumf_Mthg_zJxSAcnwjxBZWb/view?usp=sharing">
          <img src={Product3} alt="" />
        </a>
        <a href="https://drive.google.com/file/d/1spFkkjG_dkjmYf33lSvfQ6UXc7YwMm2x/view?usp=sharing">
          <img src={Product4} alt="" />
        </a>
      </div>
      <div className="flex flex-col justify-between items-center">
        <a href="https://drive.google.com/file/d/1KdvEAeG2ncuwsBzXjjW2t9Gdpu6jqfVZ/view?usp=sharing">
          <img src={Product5} alt="" />
        </a>
      </div>
    </div>
  );
}

export default Products;
