import SoawHeader from "./../Assets/soaw-header.png";
import Pdf from "./../Assets/pdf.png";
import { MouseEventHandler } from "react";

function Soaw() {
  const handleClick: MouseEventHandler<HTMLButtonElement> = () => {
    window.open(
      "https://drive.google.com/file/d/1SQMwA8SCoW8HE1SkbSKh8sjTtQdar3fK/view?usp=sharing",
      "_blank"
    );
  };

  return (
    <div>
      <div className="px-52">
        <img src={SoawHeader} alt="" className="w-[70rem]" />

        <div className="py-20 flex flex-col items-center">
          <button className="px-64 w-full mb-5 py-5 bg-[#9AA1AB] rounded-full text-[#602664] font-semibold text-3xl">
            Introduction
          </button>
          <button className="px-64 w-full mb-5 py-5 bg-[#9AA1AB] rounded-full text-[#602664] font-semibold text-3xl">
            Body Part
          </button>
          <button className="px-64 w-full mb-10 py-5 bg-[#9AA1AB] rounded-full text-[#602664] font-semibold text-3xl">
            Conclusion
          </button>

          <button
            className="flex flex-row items-center px-8 py-2 text-gray-100 text-2xl bg-gradient-to-r from-[#823287] to-[#602664] rounded-3xl"
            onClick={handleClick}
          >
            <img src={Pdf} alt="" />
            <p>Download Pdf Now</p>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Soaw;
