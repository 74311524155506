import { useNavigate } from "react-router-dom";

function IasHub() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col px-20 py-10">
      <div className="bg-[#575799] flex flex-col items-center py-5 my-2">
        <h1 className="text-2xl md:text-4xl lg:text-4xl text-white font-bold pb-5 text-center">
          Exam Guide
        </h1>
        <button
          className="bg-slate-100 py-1 px-3 rounded-3xl font-bold"
          onClick={() => {
            navigate("/exam-guide");
          }}
        >
          Click to Expand
        </button>
      </div>
      <div className="bg-[#e2622a] flex flex-col items-center py-5 my-2">
        <h1 className="text-2xl md:text-4xl lg:text-4xl text-white font-bold pb-5 text-center">
          Syllabus
        </h1>
        <button
          className="bg-slate-100 py-1 px-3 rounded-3xl font-bold"
          onClick={() => {
            navigate("/syllabus-dashboard");
          }}
        >
          Click to Expand
        </button>
      </div>
      <div className="bg-[#0d2564] flex flex-col items-center py-5 my-2">
        <h1 className="text-2xl md:text-4xl lg:text-4xl text-white font-bold pb-5 text-center">
          What makes the preparation process so difficult?
        </h1>
        <button
          className="bg-slate-100 py-1 px-3 rounded-3xl font-bold"
          onClick={() => {
            navigate("/wmpsd-dashboard");
          }}
        >
          Click to Expand
        </button>
      </div>
      <div className="bg-[#df3552] flex flex-col items-center py-5 my-2">
        <h1 className="text-2xl md:text-4xl lg:text-4xl text-white font-bold pb-5 text-center">
          Science of answer writing
        </h1>
        <button
          className="bg-slate-100 py-1 px-3 rounded-3xl font-bold"
          onClick={() => {
            navigate("/soaw-dashboard");
          }}
        >
          Click to Expand
        </button>
      </div>
    </div>
  );
}

export default IasHub;
