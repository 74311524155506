import { useNavigate } from "react-router-dom";
import JoshLogo from "./../Assets/iashub_logo.png";

function Home() {
  const navigate = useNavigate();
  return (
    <div>
      {/* <img
        src={HomeImg}
        alt="hero-imag"
        className="h-screen bg-cover bg-center w-full"
      /> */}
      <div className="absolute top-0 inset-x-0 bottom-0 flex flex-col items-center justify-center z-5">
        <img src={JoshLogo} alt="" className="h-36" />
        <h1 className="text-white text-2xl lg:text-4xl pb-4 text-black my-10 font-bold">
          Welcome to JoshTalks UPSC Counselling
        </h1>
        <button
          className="px-16 py-2 text-gray-100 text-2xl bg-gradient-to-r from-[#F7C500] to-[#FF806D] rounded-3xl"
          onClick={() => navigate("/dashboard")}
        >
          Start
        </button>
      </div>
    </div>
  );
}

export default Home;
