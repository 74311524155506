import Mockup from "./../Assets/hero-exam.png";
import JoshTalks from "./../Assets/logo.png";

import Text from "./../Assets/text.png";
import Live from "./../Assets/live.png";
import Adobe from "./../Assets/adobe.png";
import Qna from "./../Assets/qna.png";

import { useNavigate } from "react-router-dom";

function ExamGuide() {
  const navigate = useNavigate();

  return (
    <div className="">
      <div className="flex flex-row justify-between">
        <div className="py-32 px-20">
          <div className="flex flex-row items-center justify-between w-80">
            <img src={JoshTalks} alt="" />
            <hr className="hidden md:block lg:block bg-[#602664] w-1 h-16 border-none" />
            <p className="text-4xl font-bold text-[#602664]">IAS</p>
          </div>
          <h1 className="text-3xl pt-6 text-[#602664] font-bold">
            The Most
            <span className="text-[#FF6D00]"> Comprehensive </span>
            UPSC CSE Prep Program
          </h1>
          <div className="flex flex-row items-center justify-between pt-10">
            <img src={Text} alt="" />
            <img src={Live} alt="" />
            <img src={Adobe} alt="" />
            <img src={Qna} alt="" />
          </div>

          <button
            className="py-2 px-20 bg-[#602664]
          text-white rounded-3xl mt-10 text-xl font-bold"
            onClick={() => {
              navigate("/exam-details");
            }}
          >
            Know More
          </button>
        </div>
        <div>
          <img src={Mockup} alt="" className=" w-[39.7rem] overflow-y-hidden" />
        </div>
      </div>
    </div>
  );
}

export default ExamGuide;
