import LoginImg from "./../Assets/login.png";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IasHubLogo from "./../Assets/iashub-logo.png";
import Counselling from "./../Assets/Counselling.png";
import Google from "./../Assets/google.png";

// import "typeface-inter/index.css";

function Login() {
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      localStorage.setItem("email", email);
      navigate("/home");
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className="flex flex-row items-center justify-between overflow-x-hidden overflow-y-hidden relative pr-52 h-[100vh]">
      <img
        src={LoginImg}
        alt=""
        className="h-[90vh] -translate-x-24 translate-y-10"
      />
      <img
        className="absolute top-20 right-56 -z-10 w-[70vw]"
        src={Counselling}
        alt=""
      />
      <div className="mt-96 flex flex-col items-center">
        <h1 className="text-2xl font-bold pb-5 font-inter">
          Welcome to <span className="text-[#823287] ">UPSC Counselling</span>
        </h1>
        <button
          className="px-8 py-2 text-gray-500 font-semibold border shadow-2xl bg-white text-xl bg-gradient-to-r rounded-xl"
          onClick={handleClickOpen}
        >
          <div className="flex flex-row items-center">
            <img src={Google} alt="" className="mr-3" />
            <p>Continue with Email</p>
          </div>
        </button>
        <Dialog open={open} onClose={handleClose}>
          <form onSubmit={handleSubmit}>
            <DialogTitle>
              <div className="flex flex-col items-center px-10 py-30">
                <img src={IasHubLogo} alt="" className="" />
              </div>
            </DialogTitle>
            <DialogContent>
              <h2 className="mx-10 text-gray-500 ">
                Enter your work email here
              </h2>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="mt-10 mb-2 rounded-xl w-full"
              />
            </DialogContent>
            <DialogActions>
              <button
                type="submit"
                onClick={handleClose}
                className="w-full mx-3 py-2  text-white bg-[#823287]"
              >
                Log in
              </button>
            </DialogActions>
          </form>
        </Dialog>
        <h6 className="text-[#823287] pt-5 font-semibold">
          Please sign in with your work email address!
        </h6>

        {/* <form onSubmit={handleSubmit}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="my-10 rounded-3xl"
          />

          <button
            type="submit"
            className="px-8 py-2 text-gray-100 text-2xl bg-gradient-to-r from-[#F7C500] to-[#FF806D] rounded-3xl"
          >
            Submit
          </button>
        </form> */}
      </div>
    </div>
  );
}

export default Login;
