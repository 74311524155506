import SyllabusHeader from "./../Assets/syllabus-header.png";

import Syllabus1 from "./../Assets/syllabus1.2.png";
import Syllabus2 from "./../Assets/syllabus1.3.png";
import Syllabus3 from "./../Assets/syllabus1.4.png";
import Syllabus4 from "./../Assets/syllabus1.5.png";
import Syllabus5 from "./../Assets/syllabus1.6.png";
import Syllabus6 from "./../Assets/syllabus1.7.png";
import Syllabus7 from "./../Assets/syllabus1.8.png";
import Syllabus8 from "./../Assets/syllabus1.9.png";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination, Navigation } from "swiper";

function Syllabus() {
  return (
    <div>
      <div className="px-52 bg-[#F4F6FE]">
        <img src={SyllabusHeader} alt="" className="w-[70rem]" />

        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Pagination, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <img src={Syllabus1} alt="" className="w-[60rem] ml-24" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={Syllabus2} alt="" className="w-[60rem] ml-24" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={Syllabus3} alt="" className="w-[60rem] ml-24" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={Syllabus4} alt="" className="w-[60rem] ml-20" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={Syllabus5} alt="" className="w-[60rem] ml-20" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={Syllabus6} alt="" className="w-[60rem] ml-20" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={Syllabus7} alt="" className="w-[60rem] ml-20" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={Syllabus8} alt="" className="w-[60rem] ml-20" />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}

export default Syllabus;
