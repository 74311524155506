import JTLogo from "./../Assets/logo.png";
import WLogo from "./../Assets/whatsapp.png";
import ProfileLogo from "./../Assets/profile.png";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";

import IasHub from "./../Assets/iashub.png";
import IasWebsite from "./../Assets/iaswebsite.png";
import Payment from "./../Assets/payment.png";
import Products from "./../Assets/products.png";
import Book from "./../Assets/book.png";

function Navigation() {
  const nameFromLocalStorage = localStorage.getItem("name");

  let truncatedText = "";

  const location = useLocation();

  let normalCss = "flex flex-row";
  let highlightedCss = "flex flex-row text-yellow-300 ";

  if (nameFromLocalStorage) {
    truncatedText =
      nameFromLocalStorage.length > 15
        ? nameFromLocalStorage.substring(0, 15 - 3) + "..."
        : nameFromLocalStorage;
  }
  return (
    <div>
      <div className="bg-[#823287] px-10 flex flex-row items-center justify-between">
        <img src={JTLogo} alt="" className="w-24" />
        <a className="text-white text-2xl px-2 font-bold" href="/dashboard">
          Counselling
        </a>

        <div className="flex flex-row items-center justify-between w-[65rem] px-36 text-white">
          <a href="https://aitestified.ai/course/civil-services-suitability-test">
            <div className={normalCss}>
              <img src={Book} alt="" className="pr-2" />
              Assessment
            </div>
          </a>
          <a href="/iashub">
            <div
              className={
                location.pathname === "/iashub" ? highlightedCss : normalCss
              }
            >
              <img src={IasHub} alt="" className="pr-2" />
              IAS Hub Programs
            </div>
          </a>
          <a href="/products">
            <div
              className={
                location.pathname === "/products" ? highlightedCss : normalCss
              }
            >
              <img src={Products} alt="" className="pr-2" />
              Product
            </div>
          </a>
          <a href="https://rzp.io/l/GuwcKVtDY">
            <div className={normalCss}>
              <img src={Payment} alt="" className="pr-2" />
              Payment Portal
            </div>
          </a>
          <a href="https://theiashub.com/">
            <div className={normalCss}>
              <img src={IasWebsite} alt="" className="pr-2" />
              IAS Hub Website
            </div>
          </a>
        </div>

        <img src={WLogo} alt="" />
        <div className="flex flex-row items-center justify-start px-1 py-1 rounded-full w-[10rem] bg-[#602664]">
          {/* <img
            src={imageFromLocalStorage ? imageFromLocalStorage : ProfileLogo}
            alt=""
            className="w-10 rounded-full"
          /> */}
          <p className="pl-3 text-white">{truncatedText}</p>
        </div>
      </div>
      <Outlet />
    </div>
  );
}

export default Navigation;
