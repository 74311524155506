import ExamHeader from "./../Assets/exam-guide-header.png";
import ExamGuide1 from "./../Assets/exam-guide-1.png";
import ExamGuide2 from "./../Assets/exam-guide-2.png";
import ExamGuide3 from "./../Assets/exam-guide-3.png";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

function ExamDetails() {
  return (
    <div>
      <div className="px-52 bg-[#F4F6FE]">
        <img src={ExamHeader} alt="" className="w-[70rem]" />
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          // autoplay={{
          //   delay: 2500,
          //   disableOnInteraction: false,
          // }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Pagination, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <img src={ExamGuide1} alt="" className="w-[60rem] ml-20" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={ExamGuide2} alt="" className="w-[60rem] ml-24" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={ExamGuide3} alt="" className="w-[60rem] ml-24" />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}

export default ExamDetails;
